import {observer} from 'mobx-react-lite';
import LivanLink from '@/components/core/LivanLink';
import {
  boardsSignalsByOrgIdStore,
  currentUserIsAdminProxy,
  userOrgsSignal,
} from '@/context/UserContext';
import {Settings, ThumbsUp, Plus, LayoutGrid, Truck, Users, FileCodeIcon} from 'lucide-react';
import {useLocation} from 'react-router';
import type {ComponentProps, MouseEventHandler} from 'react';
import type {LucideIcon} from 'lucide-react';

import clsx from 'clsx';
import type {MenuItems} from '@headlessui/react';
import {Transition} from '@headlessui/react';
import type {Board} from '@/model/board/types';
import type {OrgId} from '@/model/org/types';
import {StylistRole} from '@/model/role/default';
import React, {useMemo} from 'react';
import DropdownMenu, {
  type DropdownMenuItem,
  type DropdownMenuSection,
} from '@/components/core/DropdownMenu';
import {useValtioProxy, useValtioSnapshot} from '@/utils/valtio';

export type MainNavItem = {
  id: string;
  name: string;
  path?: string;
  getSubItems?: () => SubNavItem[] | undefined;
  getDropdownMenuSections?: () => DropdownMenuSection[] | undefined;
  Icon?: LucideIcon;
  isAdminOnly?: boolean;
};

type SubNavItem = Pick<MainNavItem, 'id' | 'name' | 'path' | 'Icon'>;

interface MainNavSectionProps {
  className?: string;
  items: MainNavItem[];
  onItemClick?: MouseEventHandler;
}

export const MainNavSection = observer(function MainNavSection(props: MainNavSectionProps) {
  const {items, className, onItemClick} = props;
  const currentUserIsAdmin = useValtioSnapshot(currentUserIsAdminProxy);
  return (
    <ul
      role="menu"
      className={clsx('-mx-2 space-y-1', className)}
    >
      {items.map((item) => {
        if (item.isAdminOnly && !currentUserIsAdmin) {
          return null;
        }
        return (
          <MainNavSectionItem
            key={item.id}
            item={item}
            onClick={onItemClick}
          />
        );
      })}
    </ul>
  );
});

interface MainNavSectionItemProps {
  item: MainNavItem;
  onClick?: MouseEventHandler;
}

const MainNavSectionItem = observer(function MainNavSectionItem(props: MainNavSectionItemProps) {
  const location = useLocation();
  const {item, onClick} = props;
  const {id, name, path, Icon, getSubItems, getDropdownMenuSections} = item;
  const isCurrent = item.path === location.pathname;
  const isParentCurrent = !!item.path && location.pathname.startsWith(item.path);
  const subItems = getSubItems?.() || [];

  const dropdownMenuSections: DropdownMenuSection[] = useMemo(() => {
    const dropdownMenuSections = getDropdownMenuSections?.() || [];
    if (onClick) {
      return dropdownMenuSections.map((section) => {
        return {
          ...section,
          items: section.items.map((item) => {
            return {
              ...item,
              onClick,
            };
          }),
        };
      });
    }
    return dropdownMenuSections;
  }, [getDropdownMenuSections, onClick]);

  const body = (
    <div className="group flex gap-x-3 p-2 font-semibold truncate w-full">
      {Icon ? (
        <Icon
          aria-hidden="true"
          size={24}
          className="shrink-0"
        />
      ) : (
        <span className="flex size-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-900 font-medium text-gray-400 group-hover:text-white">
          {name[0]}
        </span>
      )}
      <span className="truncate">{name}</span>
    </div>
  );

  const buttonClassName = clsx(
    isCurrent ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-900 hover:text-white',
    'flex truncate rounded-md w-full',
  );

  const anchor: ComponentProps<typeof MenuItems>['anchor'] = useMemo(() => {
    return {to: 'top', gap: 22};
  }, []);
  return (
    <li key={id}>
      {dropdownMenuSections.length ? (
        <DropdownMenu
          className={buttonClassName}
          truncate
          fullWidth
          sections={dropdownMenuSections}
          anchor={anchor}
          buttonRole="menuitem"
        >
          {body}
        </DropdownMenu>
      ) : (
        <LivanLink
          type="unstyled"
          role="menuitem"
          to={path}
          className={buttonClassName}
          onClick={onClick}
        >
          {body}
        </LivanLink>
      )}
      <Transition show={isParentCurrent}>
        {subItems && (
          <ul
            style={{
              // TODO for some reason, headless UI does not animate out properly with fixed style attr..?
              // potentially related? https://github.com/tailwindlabs/headlessui/issues/3479
              height: isParentCurrent ? subItems.length * 40 : 0,
            }}
            className={clsx(
              'ml-3',
              'transition-[height] duration-300 overflow-y-hidden',
              'data-[closed]:!h-0',
            )}
          >
            {subItems.map((subItem) => {
              const {id, name, path, Icon} = subItem;
              const isCurrent = path === location.pathname;
              const Component = path ? LivanLink : 'div';
              const IconComponent = Icon || 'div';
              return (
                <li key={id}>
                  <Component
                    type="unstyled"
                    role="menuitem"
                    to={path}
                    className={clsx(
                      path
                        ? isCurrent
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-400 hover:bg-gray-900 hover:text-white'
                        : 'text-gray-400',
                      'group flex gap-x-3 rounded-md p-2 font-semibold leading-6 items-center',
                    )}
                    onClick={path ? onClick : undefined}
                  >
                    <IconComponent
                      aria-hidden="true"
                      size={12}
                      color={isCurrent ? 'white' : 'gray'}
                      className="shrink-0"
                    />
                    <span className="truncate">{name}</span>
                  </Component>
                </li>
              );
            })}
          </ul>
        )}
      </Transition>
    </li>
  );
});

function convertBoardToSubItem(orgId: OrgId, board: Board) {
  return {
    id: board.id,
    name: board.name,
    path: `/orgs/${orgId}/boards/${board.id}`,
    Icon: LayoutGrid,
  };
}

export const OrgNavSection = observer(function OrgNavSection(props: {
  className?: string;
  onItemClick?: () => void;
}) {
  const {className} = props;
  const orgs = userOrgsSignal.value;
  const currentUserIsAdmin = useValtioSnapshot(currentUserIsAdminProxy);
  if (orgs == undefined) {
    // user not logged in
    return null;
  }

  const items: MainNavItem[] = orgs.map((org) => {
    const {id, name} = org;

    const readOnly = !org?.rolePriority || org?.rolePriority < StylistRole.priority;

    return {
      id,
      name,
      path: `/orgs/${id}`,
      getSubItems() {
        const boardSignalsSignal = boardsSignalsByOrgIdStore.getById({id});
        if (boardSignalsSignal.value) {
          const publishedBoards: Board[] = [];
          const unpublishedBoards: Board[] = [];
          for (let i = 0; i < boardSignalsSignal.value.length; i++) {
            const board = boardSignalsSignal.value[i].value;
            if (board.publishedAt) {
              publishedBoards.push(board);
            } else {
              unpublishedBoards.push(board);
            }
          }

          const subItems: MainNavItem[] = [];
          if (unpublishedBoards.length) {
            subItems.push(
              {
                id: `${id}-unpublished-header`,
                name: 'Unpublished Boards',
              },
              ...unpublishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          if (publishedBoards.length) {
            subItems.push(
              {
                id: `${id}-published-header`,
                name: 'Published Boards',
              },
              ...publishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          subItems.push(
            ...[
              {
                id: `${id}-shipments`,
                name: 'Shipments',
                path: `/orgs/${id}/shipments`,
                Icon: Truck,
              },
              currentUserIsAdmin && {
                id: `${id}-deals`,
                name: 'Deals',
                path: `/orgs/${id}/deals`,
                Icon: ThumbsUp,
              },
              {
                id: `${id}-members`,
                name: 'Members',
                path: `/orgs/${id}/members`,
                Icon: Users,
              },
              !readOnly && {
                id: `${id}-settings`,
                name: 'Settings',
                path: `/orgs/${id}/settings`,
                Icon: Settings,
              },
              currentUserIsAdmin && {
                id: `${id}-scrapes`,
                name: 'Scrapes',
                path: `/orgs/${id}/scrapes`,
                Icon: FileCodeIcon,
              },
            ].filter(Boolean),
          );
          return subItems;
        }
      },
    };
  });

  if (currentUserIsAdmin) {
    items.push({
      id: '£create', // use weird symbol to lower likelihood that these conflict
      name: 'Create an org',
      path: '/orgs/create',
      Icon: Plus,
    });
  }

  return (
    <MainNavSection
      {...props}
      items={items}
    />
  );
});
