import FooterLivanLogoSrc from '@/assets/images/logo-black-md.png';
import LivanImage from '@/components/core/LivanImage';

import LivanLink from '@/components/core/LivanLink';
import Environment from '@/config/Environment';
import {copyToClipboardWithStatusContext} from '@/utils/clipboard';

import {observer} from 'mobx-react-lite';

const Footer = observer(function Footer(props: {light?: boolean}) {
  const {light} = props;

  async function onVersionClick(e) {
    await copyToClipboardWithStatusContext(window._livanVersion);
  }

  return (
    <div className="mt-12 text-xs flex flex-col items-center gap-y-1">
      <LivanImage
        className="self-center h-full w-56"
        alt="livan"
        src={FooterLivanLogoSrc}
      />
      <div>&copy; {new Date().getFullYear()} The Styled By Collective</div>
      {!Environment.IsProd && (
        <LivanLink
          onClick={onVersionClick}
          type={light ? 'link-light' : 'link'}
        >
          Version: {window._livanVersion}
        </LivanLink>
      )}
    </div>
  );
});

export default Footer;
