import type {UIMatch} from 'react-router';
import {Link, useMatches} from 'react-router';
import React, {type ReactNode} from 'react';

import type {BaseComponentProps} from '@/components/types';
import {currentUserIsAdminProxy} from '@/context/UserContext';
import type {RouteHandle} from '@/router-types';
import {useValtioSnapshot} from '@/utils/valtio';
import clsx from 'clsx';

import Footer from '@/components/layout/Footer';
import ArrayUtils from '@/ArrayUtils';
import {observer} from 'mobx-react-lite';

type MainTabsProps = BaseComponentProps & {};

const MainTabsWrapper = observer(function MainTabsWrapper(params: {children: ReactNode}) {
  const {children} = params;
  return (
    <main className="MainTabs p-4 sm:px-6 flex flex-col flex-1">
      <div className="flex-1">{children}</div>
      <Footer />
    </main>
  );
});

export const MainTabs = observer(function MainTabs(props: MainTabsProps = {}) {
  const {className, children} = props;
  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];
  const currentUserIsAdmin = useValtioSnapshot(currentUserIsAdminProxy);

  const rootTabMatch = matches.find((match) => {
    return match.handle?.getTabs?.(matches, {
      currentUserIsAdmin: !!currentUserIsAdmin,
    });
  });
  if (!rootTabMatch) {
    return <MainTabsWrapper>{children}</MainTabsWrapper>;
  }
  const match = matches[matches.length - 1];
  const handle = match?.handle;
  if (!handle) {
    return null;
  }
  const {getTabs} = handle;
  const tabs = getTabs?.(matches, {
    currentUserIsAdmin: !!currentUserIsAdmin,
  });
  if (!tabs) {
    return <MainTabsWrapper>{children}</MainTabsWrapper>;
  }

  const currentTab = tabs.find((tab) => {
    const to = rootTabMatch.pathname + tab.href;
    return to === location.pathname;
  });

  const currentSegments = location.pathname.split('/').filter(Boolean);
  const currentSegmentsObj = ArrayUtils.ToObjectByTrue(currentSegments);

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <div className="border-b border-gray-200 px-1 sm:px-4 sm:pl-6 sm:pr-2 lg:pr-4">
        <nav
          aria-label="Tabs"
          className="-mb-px flex gap-x-2 sm:gap-x-4 overflow-x-auto"
        >
          {tabs.map((tab) => {
            const to = rootTabMatch.pathname + tab.href;
            let isCurrent: boolean;
            const toSegments = to.split('/').filter(Boolean);
            if (currentSegments.length > 3 && toSegments.length >= 3) {
              isCurrent = toSegments.every((segment) => {
                return currentSegmentsObj[segment];
              });
            } else {
              isCurrent = to === location.pathname;
            }
            return (
              <Tab
                key={tab.name}
                isCurrent={isCurrent}
                to={to}
                {...tab}
              />
            );
          })}
        </nav>
      </div>
      <MainTabsWrapper>
        <div className="flex flex-col flex-1 gap-2">
          {currentTab && <h2>{currentTab.name}</h2>}
          {children}
        </div>
      </MainTabsWrapper>
    </div>
  );
});

const Tab = observer(function Tab(props: {
  name: string;
  icon: React.ElementType;
  to: string;
  isCurrent: boolean;
}) {
  const {name, icon: Icon, to, isCurrent} = props;
  return (
    <Link
      key={name}
      to={to}
      aria-current={isCurrent ? 'page' : undefined}
      className={clsx(
        isCurrent
          ? 'border-livan-black/50 text-livan-black'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
        'group inline-flex items-center border-b-2 px-3 sm:px-1 py-4 text-sm font-medium flex-col sm:flex-row sm:items-end',
      )}
    >
      <Icon
        aria-hidden="true"
        className={clsx(
          isCurrent ? 'text-livan-black' : 'text-gray-400 group-hover:text-gray-500',
          'sm:mr-2 size-6 sm:size-5',
        )}
      />
      <h4 className="mt-1 sm:mt-0">{name}</h4>
    </Link>
  );
});
